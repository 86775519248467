import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />

      <section className="bg-primary h-[90vh] flex flex-col justify-center ">
        <div className="container flex flex-col items-center px-5 py-16 mx-auto md:flex-row lg:px-28">
          <div className="flex flex-col items-start mb-16 text-left lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 md:mb-0">
            <h2 className="mb-8 text-ms tracking-widest uppercase title-font text-gold">WeStash</h2>
            <h1 className="mb-8 text-2xl tracking-tighter md:text-5xl title-font">Next generation data storage </h1>
            <p className="mb-8 text-base leading-relaxed text-left text-blueGray-600 ">Store your data for a fraction of the cost on the blockchain.</p>
            {/* <div className="flex flex-col justify-center lg:flex-row">
              <form className="m-4 flex">
                <input className="rounded-l-lg p-4 border-t mr-0 border-b border-l text-gray-800 border-gray-200 bg-white" placeholder="your@mail.com" />
                <button className="px-8 rounded-r-lg bg-yellow-400  text-gray-800 font-bold p-4 uppercase border-yellow-500 border-t border-b border-r">Subscribe</button>
              </form>
            </div> */}
          </div>
          <div className=" w-full h-full lg:w-1/3 lg:max-w-lg md:w-1/2">
            <img className="object-cover object-center" alt="hero" src="https://images.unsplash.com/photo-1621246734333-91648581ede1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"></img>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default IndexPage;
